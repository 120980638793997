import { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import { MobileOnly } from '../../res/js/breakpoints'
import Drawer from 'react-motion-drawer'
import LanguagesButton from '../LanguagesButton'
import SocialPanel from '../SocialPanel'
import Context from '../../AppContext'
import routes from '../../routes'
import links from '../../res/data/links.json'

const BurgerMenu = ({ handleMenu }) => {
  const {
    setLanguage,
    t,
    isActiveMenu,
    setIsActiveMenu,
    isActiveLangButton,
    setIsActiveLangButton,
  } = useContext(Context)

  const isMobileOnly = useMediaQuery({ query: MobileOnly })

  const handleLanguage = value => {
    setLanguage(value)
  }

  const handleLanguageButton = () => {
    setIsActiveLangButton(!isActiveLangButton)
  }

  return (
    <>
      {isMobileOnly ? (
        <Drawer
          onChange={() => setIsActiveMenu(!isActiveMenu)}
          width={'100%'}
          right
          zIndex={100}
          open={isActiveMenu}
          className={'Drawer'}
        >
          <div className="burger-menu">
            <NavLink
              exact
              onClick={() => setIsActiveMenu(!isActiveMenu)}
              className="menu-nav__link link"
              activeClassName="menu-nav__link--active"
              to={routes.home}
            >
              {t('home')}
            </NavLink>
            <NavLink
              exact
              onClick={() => setIsActiveMenu(!isActiveMenu)}
              className="menu-nav__link link"
              activeClassName="menu-nav__link--active"
              to={routes.install}
            >
              {t('howToInstall')}
            </NavLink>
            <NavLink
              onClick={() => setIsActiveMenu(!isActiveMenu)}
              className="menu-nav__link link"
              activeClassName="menu-nav__link--active"
              to={routes.configure}
            >
              {t('configure')}
            </NavLink>

            <a
              onClick={() => setIsActiveMenu(!isActiveMenu)}
              className="menu-nav__link link"
              href={links.social.discord}
              rel="noreferrer"
            >
              {t('support')}
            </a>
            <div onClick={handleLanguageButton}>
              {isActiveLangButton ? (
                <LanguagesButton
                  handleMenu={handleMenu}
                  onChangeLang={handleLanguage}
                />
              ) : (
                <div
                  onClick={handleLanguageButton}
                  className="menu-nav__link link"
                >
                  {t('lang')}
                </div>
              )}
            </div>
            <SocialPanel />
          </div>
        </Drawer>
      ) : null}
    </>
  )
}

export default BurgerMenu
