import { useEffect } from 'react'
import { motion, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
  anomationContainer: {
    position: 'relative',
    display: 'inherit',
    justifyContent: 'inherit',
    alignItems: 'inherit',
    width: 'inherit',
  },
})

const SlideWhenVisible = ({
  delay = 0,
  isAnimate = true,
  duration = 0.7,
  transformX = 0,
  transformY = 0,
  children,
}) => {
  const controls = useAnimation()
  const [ref, inView] = useInView()

  useEffect(() => {
    if (inView) {
      controls.start('visible')
    }
  }, [controls, inView])

  const container = {
    hidden: { opacity: 0, x: transformX, y: transformY },
    visible: {
      opacity: 1,
      x: 0,
      y: 0,
      transition: { delay: delay, type: 'spring', duration: duration },
    },
  }

  return (
    <>
      {isAnimate ? (
        <motion.div
          className={useStyles.anomationContainer}
          style={{
            width: 'inherit',
            display: 'inherit',
            justifyContent: 'inherit',
            alignItems: 'inherit',
            boxShadow: 'inherit',
          }}
          ref={ref}
          animate={controls}
          initial="hidden"
          variants={container}
        >
          {children}
        </motion.div>
      ) : (
        <div className={useStyles.anomationContainer}>{children}</div>
      )}
    </>
  )
}

export default SlideWhenVisible
