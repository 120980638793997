const Metrics = {
  sendInfo: () => {
    setTimeout(() => {
      const url = new URL('https://bitsfortricks.herokuapp.com/site_ping');
      const params = {
        src: document.referrer,
      };

      const code = new URLSearchParams(document.location.search).get('code');
      if (code !== null) {
        params.srcCode = code;
      }

      url.search = new URLSearchParams(params).toString()
      fetch(url, {
        method: 'POST',
      }).catch(err => console.log(err.message));
    }, 10);
  }
}

export default Metrics;