import { useState, useEffect, useContext, useRef } from 'react'

import Context from '../AppContext'
import MarkdownComponent from '../components/MarkdownComponent'
import translateMarkdown from '../res/js/fetchMarkdown'
import Container from '../components/Container'
import MenuNav from '../components/MenuNav'
import Footer from '../components/Footer'

export const PolicyView = () => {
  const ref = useRef(null)
  const [markdownData, setMarkdown] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const { language, t } = useContext(Context)

  const autoScroll = () => {
    const offsetTop = ref.current.offsetTop

    window.scrollTo({
      top: offsetTop,
      behavior: 'smooth',
    })
  }

  useEffect(() => {
    setIsLoading(true)

    translateMarkdown('policy')
      .then(data => {
        setMarkdown(data)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [setMarkdown, language])

  useEffect(() => {
    if (!isLoading) autoScroll()
  }, [isLoading])

  return (
    <>
      <MenuNav />
      <main ref={ref}>
        <div className="markdown markdown--policy">
          <Container>
            <MarkdownComponent title={t('policyTitle')}>
              {markdownData}
            </MarkdownComponent>
          </Container>
        </div>
      </main>
      <Footer />
    </>
  )
}
