import { createUseStyles } from 'react-jss'
import { useMediaQuery } from 'react-responsive'
import SlideWhenVisible from '../SlideWhenVisible'
import { LaptopOnly } from '../../res/js/breakpoints'

const Subtitle = ({
  titleContent,
  titleEffect,
  fontSize,
  bottom,
  left = 'auto',
  isAnimate,
  delay,
  duration,
  transformX,
  transformY,
}) => {
  const isLaptopOnly = useMediaQuery({ query: LaptopOnly })

  const useStyles = createUseStyles({
    titleEffect: {
      position: 'absolute',
      display: 'block',
      width: '100%',
      textAlign: 'center',
      fontFamily: 'inherit',
      fontWeight: 'inherit',
      lineHeight: 1.2,
      letterSpacing: '0.03em',
      WebkitTextStroke: isLaptopOnly ? '1px' : '2px',
      color: 'transparent',
    },
  })

  const classes = useStyles()

  return (
    <div className="title__container ">
      {isAnimate ? (
        <>
          <SlideWhenVisible
            transformX={transformX}
            transformY={transformY}
            delay={delay}
            duration={duration}
          >
            {titleContent}
          </SlideWhenVisible>
          <SlideWhenVisible
            transformX={transformX}
            transformY={transformY}
            duration={duration - 0.1}
          >
            <p
              className={classes.titleEffect}
              style={{ fontSize: fontSize, bottom: bottom, left: left }}
            >
              {titleEffect}
            </p>
          </SlideWhenVisible>
        </>
      ) : (
        <>
          {titleContent}
          <p
            className={classes.titleEffect}
            style={{ fontSize: fontSize, bottom: bottom, left: left }}
          >
            {titleEffect}
          </p>
        </>
      )}
    </div>
  )
}

export default Subtitle
