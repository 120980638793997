import { useState, useEffect } from 'react'
import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import ReactPixel from 'react-facebook-pixel'
import TagManager from 'react-gtm-module'
import { TabletOnly } from './res/js/breakpoints'
import i18n from './i18n'
import Context from './AppContext'
import Metrics from './metrics'
import routes from './routes'
import BurgerMenu from './components/BurgerMenu'
import {
  HomeView,
  InstallView,
  ConfigureView,
  TermsView,
  PolicyView,
  CookiesView,
  DownloadView,
  DownloadAppView,
} from './views'

const publicURL = process.env.PUBLIC_URL

function App() {
  const route = useRouteMatch()

  const [language, setLanguage] = useState('')
  const [isActiveMenu, setIsActiveMenu] = useState(false)
  const [isActiveLangButton, setIsActiveLangButton] = useState(false)
  const [isDownload, setDownload] = useState(false)

  const [t] = useTranslation(['translation'])

  const isTabletOnly = useMediaQuery({ query: TabletOnly })

  const tagManagerArgs = {
    gtmId: 'GTM-K8GM2GB',
  }

  const handleButtonGetTwitch = () => {    
    ReactPixel.track('TwitchDownload')
    ReactPixel.track('AllDownloads')
  }

  const handleButtonInstallPC = async e => {
    e?.preventDefault()

    TagManager.dataLayer({
      dataLayer: { event: 'download', category: 'lead' },
    })

    ReactPixel.track('AppDownload')
    ReactPixel.track('AllDownloads')

    setDownload(true)

    fetch(`${publicURL}/files/chaostricks.zip`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/zip',
      },
    })
      .then(data => {
        window.location.assign(data.url)
      })
      .catch(err => console.log(err.message))
      .finally(() => {
        setDownload(false)
      })
  }

  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
    setLanguage(localStorage.getItem('i18nextLng'));
    Metrics.sendInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    i18n.changeLanguage(language)
  }, [language])

  useEffect(() => {
    if (isActiveMenu) {
      document.body.style = 'overflow: hidden'
      return
    }

    document.body.style = 'overflow: auto'
  }, [isActiveMenu])

  useEffect(() => {
    ReactPixel.init('695616408429262', {}, { debug: false, autoConfig: false })
    ReactPixel.pageView()
  }, [route])

  return (
    <>
      <Context.Provider
        value={{
          publicURL,
          language,
          setLanguage,
          isActiveMenu,
          setIsActiveMenu,
          isActiveLangButton,
          setIsActiveLangButton,
          isDownload,
          setDownload,
          handleButtonInstallPC,
          handleButtonGetTwitch,
          t,
        }}
      >
        {isTabletOnly ? (
          <BurgerMenu handleMenu={() => setIsActiveMenu(!isActiveMenu)} />
        ) : null}

        <Switch>
          <Route exact path={routes.home} component={HomeView} />
          <Route path={routes.install} component={InstallView} />
          <Route path={routes.configure} component={ConfigureView} />
          <Route path={routes.policy} component={PolicyView} />
          <Route path={routes.cookies} component={CookiesView} />
          <Route path={routes.download} component={DownloadView} />
          <Route path={routes.terms} component={TermsView} />
          <Route path={routes.downloadlink} component={DownloadAppView} />
        </Switch>

        {isDownload ? <Redirect to={routes.install} /> : null}
      </Context.Provider>
    </>
  )
}

export default App
