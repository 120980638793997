import { useContext, useEffect } from 'react'
import Context from '../AppContext'
import Container from '../components/Container'

export const DownloadView = () => {
  const { handleButtonInstallPC } = useContext(Context)

  useEffect(() => {
    handleButtonInstallPC()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <main className="download">
      <Container>
        {/* <Button
          handleClick={handleButtonInstallPC}
          href={routes.install}
          className="get-join__button"
        >
          {t('installApp')}
        </Button> */}
      </Container>
    </main>
  )
}
