/* eslint-disable import/no-anonymous-default-export */
export default {
  home: '/',
  install: '/how-to-install',
  configure: '/configure',
  game: '/configure/:gameId',
  policy: '/privacy-policy',
  terms: '/terms-of-service',
  download: '/bitsfortricks_app',
  cookies: '/cookies',
  downloadlink: '/download-app',
}
