import { Suspense } from 'react'
import { Route } from 'react-router-dom'
import PropagateLoader from 'react-spinners/PropagateLoader'
import MenuNav from '../components/MenuNav'
import Footer from '../components/Footer'
import GamesGuide from '../components/GamesGuide'
import ConfigureGame from '../components/ConfigureGame'
import CookiesNotification from '../components/CookiesNotification'
import routes from '../routes'

const spinnerStyle = {
  left: '50%',
  top: '200px',
}

export const ConfigureView = () => {
  return (
    <>
      <MenuNav />
      <main>
        <GamesGuide />
        <Suspense
          fallback={<PropagateLoader color={'#18b167'} css={spinnerStyle} />}
        >
          <Route path={routes.game} component={ConfigureGame} />
        </Suspense>
      </main>
      <Footer />
      <CookiesNotification />
    </>
  )
}
