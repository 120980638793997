import MenuNav from '../components/MenuNav'
import InstallGuide from '../components/InstallGuide'
import GamesGuide from '../components/GamesGuide'
import Footer from '../components/Footer'
import CookiesNotification from '../components/CookiesNotification'

export const InstallView = () => {
  return (
    <>
      <MenuNav />
      <main>
        <InstallGuide />
        <GamesGuide />
      </main>
      <Footer />
      <CookiesNotification />
    </>
  )
}
