import { useContext } from 'react'
import Context from '../../AppContext'

const LanguagesButton = ({ handleLanguageButtonLeave }) => {
  const { language, setLanguage } = useContext(Context)

  const handleChangeLanguage = e => {
    const targetLenguage = e.target.textContent

    if (targetLenguage === 'Русский') setLanguage('ru')
    if (targetLenguage === 'English') setLanguage('en')
    if (targetLenguage === 'Українська') setLanguage('uk')
    if (targetLenguage === 'Deutsch') setLanguage('de')
    if (targetLenguage === 'Español') setLanguage('es')
  }

  return (
    <div onClick={handleChangeLanguage} className="language" onMouseLeave={handleLanguageButtonLeave}>
      <div className={`language__option-container`}>
        <p
          onClick={handleLanguageButtonLeave}
          className={
            language === 'en'
              ? 'language__option language__option--en language__option--active'
              : 'language__option language__option--en'
          }
        >
          English
        </p>
        <p
          onClick={handleLanguageButtonLeave}
          className={
            language === 'ru'
              ? 'language__option language__option--ru language__option--active'
              : 'language__option language__option--ru'
          }
        >
          Русский
        </p>
        <p
          onClick={handleLanguageButtonLeave}
          className={
            language === 'uk'
              ? 'language__option language__option--uk language__option--active'
              : 'language__option language__option--uk'
          }
        >
          Українська
        </p>
        <p
          onClick={handleLanguageButtonLeave}
          className={
            language === 'es'
              ? 'language__option language__option--es language__option--active'
              : 'language__option language__option--es'
          }
        >
          Español
        </p>
        <p
          onClick={handleLanguageButtonLeave}
          className={
            language === 'de'
              ? 'language__option language__option--de language__option--active'
              : 'language__option language__option--de'
          }
        >
          Deutsch
        </p>
      </div>
    </div>
  )
}

export default LanguagesButton
