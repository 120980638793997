const Button = ({
  href,
  className = '',
  children,
  handleClick = null,
  style = null,
}) => {
  return (
    <a
      onClick={handleClick}
      href={href}
      className={`${className} button`}
      type="button"
      target="_blank"
      rel="noreferrer"
      style={style}
    >
      {children}
    </a>
  )
}

export default Button
