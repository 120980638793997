import { useContext} from 'react'
import { CookiesProvider, useCookies } from 'react-cookie'
import Context from '../../AppContext'
import Container from '../Container'
import ReactMarkdown from 'react-markdown'

const CookiesNotification = () => {
  const { t } = useContext(Context)

  const [cookies, setCookie] = useCookies();


  return (
    <CookiesProvider>
      <div className={`cookies_window ${cookies.showWindow}`}>
        <Container>
          <ReactMarkdown>{t('cookiesParagraph')}</ReactMarkdown>
          <span className="cookies_window__close" onClick={() => { setCookie('showWindow', 'close-view', { path: '/' }); }}>&#10006;</span>
        </Container>
      </div>
    </CookiesProvider>
  )
}

export default CookiesNotification