export const MobileOnly = '(max-width: 850px)'
export const Mobile = '(min-width: 320px)'
export const TabletOnly = '(max-width: 1279px)'
export const Tablet = '(min-width: 768px)'
export const LaptopOnly = '(max-width: 1439px)'
export const Laptop = '(min-width: 1280px)'
export const LaptopLargeOnly = '(max-width: 1679px)'
export const LaptopLarge = '(min-width: 1440px)'
export const DesktopOnly = '(max-width: 2000px)'
export const Desktop = '(min-width: 1680px)'
export const DesktopLarge = '(min-width: 2001px)'
