const publicURL = process.env.PUBLIC_URL
const languages = ['ru', 'en', 'de', 'es', 'uk']

const fetchMarkdown = async subject => {
  const language = languages.includes(localStorage.getItem('i18nextLng'))
  ? localStorage.getItem('i18nextLng')
  : 'en'

  const markdownLink = `${publicURL}/markdown/${language
    .slice(0, 2)
    .toLowerCase()}/${subject}.md`

  try {
    const markdownData = await fetch(markdownLink)
      .then(res => res.text())
      .then(text => text)

    return markdownData
  } catch (error) {
    console.log(error)
  }
}

export default fetchMarkdown
