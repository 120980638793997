import { useContext } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useLocation } from 'react-router-dom'
import Context from '../../AppContext'
import { Link } from 'react-router-dom'
import routes from '../../routes'
import Container from '../Container'
import SocialPanel from '../SocialPanel'
import { MobileOnly } from '../../res/js/breakpoints'

const Footer = () => {
  const { t } = useContext(Context)

  const location = useLocation()

  const isMobileOnly = useMediaQuery({ query: MobileOnly })

  return (
    <footer className="footer">
      <Container>
        <ul className="footer__list">
          {isMobileOnly ? null : (
            <>
              {location.pathname === routes.install ||
              location.pathname === routes.configure ? (
                <li className="footer__item">
                  <Link className="footer__link link" to={routes.home}>
                    {t('home')}
                  </Link>
                </li>
              ) : null}
              {location.pathname === routes.install ? null : (
                <li className="footer__item">
                  <Link className="footer__link link" to={routes.install}>
                    {t('howToInstall')}
                  </Link>
                </li>
              )}
              {location.pathname === routes.configure ? null : (
                <li className="footer__item">
                  <Link className="footer__link link" to={routes.configure}>
                    {t('configure')}
                  </Link>
                </li>
              )}
            </>
          )}
          <li className="footer__item">
            <Link className="footer__link link" to={routes.terms}>
              {t('terms')}
            </Link>
          </li>
          <li className="footer__item">
            <Link className="footer__link link" to={routes.policy}>
              {t('policy')}
            </Link>
          </li>
        </ul>

        {isMobileOnly ? null : <SocialPanel />}
      </Container>
    </footer>
  )
}

export default Footer
