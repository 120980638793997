import ReactMarkdown from 'react-markdown'

const MarkdownComponent = ({ title, children }) => {
  return (
    <>
      <h2 className="markdown__title">{title}</h2>
      <div className="markdown__body">
        <ReactMarkdown>{String(children)}</ReactMarkdown>
      </div>
    </>
  )
}

export default MarkdownComponent
