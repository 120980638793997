import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import PropagateLoader from 'react-spinners/PropagateLoader'
import './index.scss'
import './i18n'
import App from './App'

const spinnerStyle = {
  left: '50%',
  top: '200px',
}

ReactDOM.render(
  <BrowserRouter>
    <React.StrictMode>
      <Suspense
        fallback={<PropagateLoader color={'#18b167'} css={spinnerStyle} />}
      >
        <App />
      </Suspense>
    </React.StrictMode>
  </BrowserRouter>,
  document.getElementById('root'),
)
