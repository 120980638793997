import { useContext, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import { Turn as Hamburger } from 'hamburger-react'
import Headroom from 'react-headroom'
import Context from '../../AppContext'
import routes from '../../routes'
import Container from '../Container'
import SocialPanel from '../SocialPanel'
import LanguagesButton from '../LanguagesButton'
import {
  MobileOnly,
  Tablet,
  Laptop,
  Desktop,
  DesktopLarge,
} from '../../res/js/breakpoints'

const MenuNav = () => {
  const {
    isActiveMenu,
    setIsActiveMenu,
    isActiveLangButton,
    setIsActiveLangButton,
    t,
  } = useContext(Context)

  const [isActiveSocialPanel, setActiveSocialPanel] = useState(false)

  const isMobileOnly = useMediaQuery({ query: MobileOnly })
  const isTablet = useMediaQuery({ query: Tablet })
  const isLaptop = useMediaQuery({ query: Laptop })
  const isDesktop = useMediaQuery({ query: Desktop })
  const isDesktopLarge = useMediaQuery({ query: DesktopLarge })

  const HeadroomStyles = {
    display: 'flex',
    justifyContent: isMobileOnly ? 'flex-end' : 'space-between',
    alignItems: 'center',
    height: isDesktopLarge ? '53px' : '49px',
    zIndex: 999,
    backgroundColor: '#18b167',
    borderBottom: 'solid 4px #8ffcbd',
  }

  const handleLanguageButtonEnter = () => {
    setIsActiveLangButton(true)
    setActiveSocialPanel(false)
  }

  const handleLanguageButtonLeave = () => {
    setIsActiveLangButton(false)
  }

  const handleSocialPanelEnter = e => {
    e.preventDefault()

    setIsActiveLangButton(false)
    setActiveSocialPanel(true)
  }

  const handleAlllLeave = () => {
    setActiveSocialPanel(false)
    setIsActiveLangButton(false)
  }

  return (
    <nav className="menu-nav">
      <Headroom style={HeadroomStyles}>
        <Container>
          {isMobileOnly ? null : (
            <div className="menu-nav__left">
              <NavLink
                exact
                onMouseEnter={handleAlllLeave}
                className="menu-nav__link link"
                activeClassName="menu-nav__link--active"
                to={routes.home}
              >
                {t('home')}
              </NavLink>
              <NavLink
                exact
                onMouseEnter={handleAlllLeave}
                className="menu-nav__link link"
                activeClassName="menu-nav__link--active"
                to={routes.install}
              >
                {t('howToInstall')}
              </NavLink>
              <NavLink
                onMouseEnter={handleAlllLeave}
                className="menu-nav__link link"
                activeClassName="menu-nav__link--active"
                to={routes.configure}
              >
                {t('configure')}
              </NavLink>
            </div>
          )}

          {isMobileOnly ? (
            <Hamburger
              rounded
              onToggle={toggled => {
                if (toggled) {
                  setIsActiveLangButton(false)
                }
              }}
              label="Show menu"
              toggled={isActiveMenu}
              toggle={setIsActiveMenu}
            />
          ) : (
            <div className="menu-nav__right">
              {isActiveSocialPanel ? (
                <SocialPanel isMainManu styles={'menu-nav__link link'} />
              ) : (
                <p
                  onMouseEnter={handleSocialPanelEnter}
                  onClick={handleSocialPanelEnter}
                  className={
                    isActiveLangButton
                      ? 'menu-nav__link menu-nav__link--slideRight menu-nav__support link'
                      : 'menu-nav__link menu-nav__support link'
                  }
                >
                  {t('support')}
                </p>
              )}
              <div>
                {isActiveLangButton ? (
                  <LanguagesButton
                    handleLanguageButtonLeave={handleLanguageButtonLeave}
                  />
                ) : (
                  <div
                    onClick={handleLanguageButtonEnter}
                    onMouseEnter={handleLanguageButtonEnter}
                    className="menu-nav__link link"
                  >
                    {t('lang')}
                  </div>
                )}
              </div>
            </div>
          )}
        </Container>
      </Headroom>
    </nav>
  )
}

export default MenuNav
