import { useContext } from 'react'
import { useMediaQuery } from 'react-responsive'
import PropagateLoader from 'react-spinners/PropagateLoader'
import Context from '../../AppContext'
import Container from '../Container'
import Title from '../Title'
import Subtitle from '../Subtitle'
import Button from '../Button'
import SlideWhenVisible from '../SlideWhenVisible'
import {
  Mobile,
  Tablet,
  Laptop,
  LaptopLarge,
  Desktop,
  DesktopLarge,
} from '../../res/js/breakpoints'
import links from '../../res/data/links.json'

const spinnerStyle = {
  position: 'absolute',
  left: '50%',
  top: '40%',
}

const InstallGuide = () => {
  const isMobile = useMediaQuery({ query: Mobile })
  const isTablet = useMediaQuery({ query: Tablet })
  const isLaptop = useMediaQuery({ query: Laptop })
  const isLaptopLarge = useMediaQuery({ query: LaptopLarge })
  const isDesktop = useMediaQuery({ query: Desktop })
  const isDesktopLarge = useMediaQuery({ query: DesktopLarge })

  const { publicURL, t, isDownload, handleButtonInstallPC, handleButtonGetTwitch } =
    useContext(Context)

  return (
    <section className="install">
      <Container>
        <Title
          titleContent={
            <h2 className="install__title title">{t('installTitle')}</h2>
          }
          titleEffect={t('installTitle')}
          fontSize={
            (isDesktopLarge && 75) ||
            (isDesktop && 72) ||
            (isLaptop && 60) ||
            (isTablet && 47) ||
            (isMobile && '5vw')
          }
          bottom={
            (isDesktopLarge && 53) ||
            (isDesktop && 52) ||
            (isLaptopLarge && 42) ||
            (isLaptop && 36) ||
            (isTablet && 28) ||
            (isMobile && '1em')
          }
          isAnimate={isLaptop ? true : false}
          delay={0.4}
          transformY={-100}
          duration={0.8}
        />

        <Subtitle
          titleContent={
            <h3 className="install__subtitle install__subtitle--disclaimer title">
              {t('installDisclaimer')}
            </h3>
          }
          titleEffect={t('installDisclaimer')}
          fontSize={
            (isDesktopLarge && 40) ||
            (isDesktop && 32) ||
            (isLaptop && 28) ||
            (isTablet && 35) ||
            (isMobile && '4vw')
          }
          bottom={
            (isDesktopLarge && -3) ||
            (isDesktop && -2) ||
            (isLaptopLarge && -2) ||
            (isLaptop && -2) ||
            (isTablet && -2) ||
            (isMobile && '-2%')
          }
          left={
            (isDesktopLarge && 'calc(50% + 6px)') ||
            (isDesktop && 'calc(50% + 4px)') ||
            (isLaptopLarge && 'calc(50% + 4px)') ||
            (isLaptop && 'calc(50% + 4px)') ||
            (isTablet && 'calc(50% + 4px)') ||
            (isMobile && 'calc(50% + 0.6%)')
          }
        />
        <ul className="install__list">
          <SlideWhenVisible
            isAnimate={isLaptop ? true : false}
            delay={0.7}
            duration={2}
            transformY={-1}
            transformX={-1}
          >
            <li className="install__item">
              <h4 className="install__steps">{t('step_1')}</h4>
              <Subtitle
                titleContent={
                  <h3 className="install__subtitle title">
                    {t('installOnTwitch')}
                  </h3>
                }
                titleEffect={t('installOnTwitch')}
                fontSize={
                  (isDesktopLarge && 40) ||
                  (isDesktop && 32) ||
                  (isLaptop && 28) ||
                  (isTablet && 35) ||
                  (isMobile && '4vw')
                }
                bottom={
                  (isDesktopLarge && -3) ||
                  (isDesktop && -2) ||
                  (isLaptopLarge && -2) ||
                  (isLaptop && -2) ||
                  (isTablet && -2) ||
                  (isMobile && '-2%')
                }
                left={
                  (isDesktopLarge && 6) ||
                  (isDesktop && 4) ||
                  (isLaptopLarge && 4) ||
                  (isLaptop && 4) ||
                  (isTablet && 4) ||
                  (isMobile && '0.6%')
                }
              />
              <ol className="install__text-list">
                {t('installOnTwitch_subparagraph', { returnObjects: true }).map(
                  (el, idx) => {
                    if (idx === 0) {
                      return (
                        <>
                          <li className="install__text-item" key={el}>
                            <p className="install__text">{el} </p>
                          </li>
                          <Button
                            className="install__button"
                            href={links.extension}
                            handleClick={handleButtonGetTwitch}
                          >
                            {t('getItOnTwitch')}
                          </Button>
                        </>
                      )
                    }

                    return (
                      <li className="install__text-item" key={el}>
                        <p className="install__text">{el} </p>
                      </li>
                    )
                  },
                )}
              </ol>
            </li>
          </SlideWhenVisible>
          <SlideWhenVisible
            isAnimate={isLaptop ? true : false}
            delay={1.2}
            duration={2}
            transformY={-1}
            transformX={1}
          >
            <li className="install__item">
              <h4 className="install__steps">{t('step_2')}</h4>
              <Subtitle
                titleContent={
                  <h3 className="install__subtitle title">
                    {t('installOnPC')}
                  </h3>
                }
                titleEffect={t('installOnPC')}
                fontSize={
                  (isDesktopLarge && 40) ||
                  (isDesktop && 32) ||
                  (isLaptop && 28) ||
                  (isTablet && 35) ||
                  (isMobile && '4vw')
                }
                bottom={
                  (isDesktopLarge && -3) ||
                  (isDesktop && -2) ||
                  (isLaptopLarge && -2) ||
                  (isLaptop && -2) ||
                  (isTablet && -2) ||
                  (isMobile && '-2%')
                }
                left={
                  (isDesktopLarge && 6) ||
                  (isDesktop && 4) ||
                  (isLaptopLarge && 4) ||
                  (isLaptop && 4) ||
                  (isTablet && 4) ||
                  (isMobile && '0.6%')
                }
              />
              <ol className="install__text-list">
                {t('installOnPC_subparagraph', { returnObjects: true }).map(
                  (el, idx) =>
                    idx === 0 ? (
                      <>
                        <li className="install__text-item">
                          <p className="install__text">{el} </p>
                        </li>
                        <Button
                          handleClick={handleButtonInstallPC}
                          className="install__button"
                          href={`${publicURL}/files/chaostricks.zip`}
                          style={isDownload ? { color: 'transparent' } : null}
                        >
                          {isDownload ? (
                            <>
                              <PropagateLoader
                                css={spinnerStyle}
                                color="#fff"
                                size={10}
                              />
                              {t('installApp')}
                            </>
                          ) : (
                            t('installApp')
                          )}
                        </Button>
                      </>
                    ) : (
                      <li className="install__text-item">
                        <p className="install__text">{el} </p>
                      </li>
                    ),
                )}
              </ol>
            </li>
          </SlideWhenVisible>
        </ul>
      </Container>
    </section>
  )
}

export default InstallGuide
