import { useState, useEffect, useRef, useContext } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import PropagateLoader from 'react-spinners/PropagateLoader'
import Context from '../../AppContext'
import Container from '../Container'
import MarkdownComponent from '../MarkdownComponent'
import games from '../../res/data/games.json'
import fetchMarkdown from '../../res/js/fetchMarkdown'

const spinnerStyle = {
  left: '50%',
}

const ConfigureGame = () => {
  const ref = useRef(null)
  const location = useLocation()
  const { gameId } = useParams()
  const [currentGame, setCurrentGame] = useState({})
  const [markdownData, setMarkdown] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const { language, t } = useContext(Context)

  const autoScroll = () => {
    const offsetTop = ref.current.offsetTop

    window.scrollTo({
      top: offsetTop,
      behavior: 'smooth',
    })
  }

  useEffect(() => {
    setCurrentGame(games.find(({ game }) => game === gameId))
  }, [gameId])

  useEffect(() => {
    if (!isLoading) autoScroll()
  }, [isLoading, markdownData])

  useEffect(() => {
    setIsLoading(true)

    if (currentGame.markdownName) {
      fetchMarkdown(currentGame.markdownName)
        .then(data => {
          setMarkdown(data)
        })
        .finally(() => {
          setTimeout(() => {
            setIsLoading(false)
          }, 500)
        })
    }
  }, [currentGame.markdownName])

  const { gameOrigin } = currentGame

  return (
    <div ref={ref} className="markdown">
      {location?.pathname ? (
        <Container>
          {isLoading ? (
            <PropagateLoader color={'#8626fe'} css={spinnerStyle} />
          ) : (
            <MarkdownComponent
              title={
                language === 'ru'
                  ? `${t('configureSubtitle')} "${gameOrigin}"`
                  : `${gameOrigin} ${t('configureSubtitle')}`
              }
            >
              {markdownData}
            </MarkdownComponent>
          )}
        </Container>
      ) : null}
    </div>
  )
}

export default ConfigureGame
