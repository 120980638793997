import React from 'react'

const MenuNavLazy = React.lazy(() => import('../components/MenuNav'))
const HeroLazy = React.lazy(() => import('../components/Hero'))
const HowToWorkLazy = React.lazy(() => import('../components/HowToWork'))
const StreamersListLazy = React.lazy(() => import('../components/StreamersList'))
const GamesListLazy = React.lazy(() => import('../components/GamesList'))
const GetJoinLazy = React.lazy(() => import('../components/GetJoin'))
const FooterLazy = React.lazy(() => import('../components/Footer'))
const CookiesNotificationLazy = React.lazy(() => import('../components/CookiesNotification'))

export const HomeView = () => {
  return (
    <>
      <MenuNavLazy />
      <main>
        <HeroLazy />
        <HowToWorkLazy />
        <StreamersListLazy />
        <GamesListLazy />
        <GetJoinLazy />
      </main>
      <FooterLazy />
      <CookiesNotificationLazy />
    </>
  )
}
