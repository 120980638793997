import { useRef, useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import { NavLink } from 'react-router-dom'
import Context from '../../AppContext'
import Title from '../Title'
import Container from '../Container'
import games from '../../res/data/games.json'
import {
  Mobile,
  Tablet,
  LaptopOnly,
  Laptop,
  LaptopLarge,
  Desktop,
  DesktopLarge,
} from '../../res/js/breakpoints'
import routes from '../../routes'
import ReactPixel from 'react-facebook-pixel'

const publicURL = process.env.PUBLIC_URL

const GamesGuide = () => {
  const location = useLocation()

  const isMobile = useMediaQuery({ query: Mobile })
  const isTablet = useMediaQuery({ query: Tablet })
  const isLaptopOnly = useMediaQuery({ query: LaptopOnly })
  const isLaptop = useMediaQuery({ query: Laptop })
  const isLaptopLarge = useMediaQuery({ query: LaptopLarge })
  const isDesktop = useMediaQuery({ query: Desktop })
  const isDesktopLarge = useMediaQuery({ query: DesktopLarge })

  const { t } = useContext(Context)
  const sectionRef = useRef(null)

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [])

  const handleExtension = (gameName) => {
    ReactPixel.track(`${gameName}-View`)
  }

  return (
    <section ref={sectionRef} className="games-guide">
      {isLaptopOnly ? (
        <>
          <Title
            titleContent={
              <h2 className="games-guide__title title">
                {location.pathname === routes.install
                  ? t('step_3')
                  : t('configureTitle')}
              </h2>
            }
            titleEffect={
              location.pathname === routes.install
                ? t('step_3')
                : t('configureTitle')
            }
            fontSize={
              (isDesktopLarge && 75) ||
              (isDesktop && 72) ||
              (isLaptop && 60) ||
              (isTablet && 47) ||
              (isMobile && '5vw')
            }
            bottom={
              (isDesktopLarge && 53) ||
              (isDesktop && 52) ||
              (isLaptopLarge && 42) ||
              (isLaptop && 36) ||
              (isTablet && 28) ||
              (isMobile && '1em')
            }
            isAnimate={isLaptop ? true : false}
            delay={0.4}
            transformY={-100}
            duration={0.8}
          />
          <ul className="games-guide__list">
            {games.map(({ game, markdownName, gameOrigin, poster }) => (
              <li key={game} className="games-guide__item">
                <NavLink
                  to={{
                    pathname: `/configure/${game}`,
                  }}
                  className="games-guide__link"
                  activeClassName="games-guide__link--active"
                >
                  <img
                    onClick={()=>handleExtension(markdownName)}
                    className="games-guide__poster"
                    src={`${publicURL}/img/games/${poster}`}
                    alt={`${gameOrigin} poster`}
                  />
                </NavLink>
              </li>
            ))}
          </ul>
        </>
      ) : (
        <Container>
          <Title
            titleContent={
              <h2 className="games-guide__title title">
                {location.pathname === routes.install
                  ? t('step_3')
                  : t('configureTitle')}
              </h2>
            }
            titleEffect={
              location.pathname === routes.install
                ? t('step_3')
                : t('configureTitle')
            }
            fontSize={
              (isDesktopLarge && 75) ||
              (isDesktop && 72) ||
              (isLaptop && 60) ||
              (isTablet && 47) ||
              (isMobile && '5vw')
            }
            bottom={
              (isDesktopLarge && 53) ||
              (isDesktop && 52) ||
              (isLaptopLarge && 42) ||
              (isLaptop && 36) ||
              (isTablet && 28) ||
              (isMobile && '0.9em')
            }
            isAnimate={isLaptop ? true : false}
            delay={0.4}
            transformY={-100}
            duration={0.8}
          />
          <ul className="games-guide__list">
            {games.map(({ game, markdownName, gameOrigin, poster }) => (
              <li key={game} className="games-guide__item">
                <NavLink
                  to={{
                    pathname: `/configure/${game}`,
                  }}
                  className="games-guide__link"
                  activeClassName="games-guide__link--active"
                >
                  <img
                    onClick={()=>handleExtension(markdownName)}
                    className="games-guide__poster"
                    src={`${publicURL}/img/games/${poster}`}
                    alt={`${gameOrigin} poster`}
                  />
                </NavLink>
              </li>
            ))}
          </ul>
        </Container>
      )}
    </section>
  )
}

export default GamesGuide
